.h100 {
    height: 100%;
  }
  
  @mixin elipsis($val) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $val;
    -webkit-box-orient: vertical;
  }
  
  @function calculateRatio($base-size, $ratio) {
    $result: $base-size * $ratio;
    @if ($result < 0) {
      @return $base-size * $ratio;
    } @else if ($result > 10) {
      @return $base-size * $ratio;
    } @else {
      @return 9;
    }
  }
  
  @mixin RP($properties) {
    @include spreadProperty($properties, 1);
  
    @media screen and (max-width: 576px) {
      @include spreadProperty($properties, calc(992 / 1400));
    }
  
    @media screen and (min-width: 576px) {
      @include spreadProperty($properties, calc(992 / 1400));
    }
  
    @media screen and (min-width: 768px) {
      @include spreadProperty($properties, calc(992 / 1400));
    }
  
    @media screen and (min-width: 992px) {
      @include spreadProperty($properties, calc(992 / 1400));
    }
  
    @media screen and (min-width: 1200px) {
      @include spreadProperty($properties, calc(1200 / 1400));
    }
  
    @media screen and (min-width: 1400px) {
      @include spreadProperty($properties, calc(1400 / 1400));
    }
  }
  
  @mixin MRP($properties) {
    @include spreadProperty($properties, 1);
  
    @media screen and (min-width: 568px) {
      @include spreadProperty($properties, calc(992 / 1400));
    }
  
    @media screen and (min-width: 768px) {
      @include spreadProperty($properties, calc(992 / 1400));
    }
  
    @media screen and (min-width: 992px) {
      @include spreadProperty($properties, calc(1200 / 1400));
    }
  
    @media screen and (min-width: 1200px) {
      @include spreadProperty($properties, calc(1200 / 1400));
    }
  
    @media screen and (min-width: 1400px) {
      @include spreadProperty($properties, calc(1200 / 1400));
    }
  }
  
  @mixin spreadProperty($myArray, $ratioValue) {
    @each $name, $value in $myArray {
      $key: $name;
      $value: calculateRatio($value, $ratioValue) + px;
      #{$key}: $value;
    }
  }
  
  @function calculatePixels($screenSize, $value) {
    $ratio: calc($screenSize/1400);
    $result: $value * $ratio;
    @return $result + px;
  }
  
  .customContainer {
    margin: auto;
  }
  
  @media screen and (max-width: 576px) {
    .customContainer {
      max-width: 90%;
    }
  }
  
  @media screen and (min-width: 576px) {
    .customContainer {
      max-width: 540px;
    }
  }
  
  @media screen and (min-width: 768px) {
    .customContainer {
      max-width: 720px;
    }
  }
  
  @media screen and (min-width: 992px) {
    .customContainer {
      max-width: 960px;
    }
  }
  
  @media screen and (min-width: 1200px) {
    .customContainer {
      max-width: 1140px;
    }
  }
  
  @media screen and (min-width: 1400px) {
    .customContainer {
      max-width: 1320px;
    }
  }
  