@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";




.imgSection {

    .imgContainer {

        .img1 {
            @include RP(("height": 500,
                    "border-radius": 16,
                ));
            width: 100%;
            object-fit: cover;
        }

        .img2 {
            @include RP(("height": 156.5,
                    "border-radius": 16,
                ));
            width: 100%;
            object-fit: cover;
        }

        .magnifyContainer {
            display: none;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            background-color: rgba($color: #000000, $alpha: 0.3);
            align-items: center;
            justify-content: center;
            @include RP(("border-radius": 16,
                ));

            .iconContainer {
                @include RP(("height": 48,
                        "width": 48,
                    ));
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: white;
                border-radius: 100%;

                svg {
                    @include RP(("height": 16,
                            "width": 16,
                        ));
                }
            }
        }

        .moreContainer {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            background-color: rgba($color: #000000, $alpha: 0.3);
            display: flex;
            align-items: center;
            justify-content: center;
            @include RP(("border-radius": 16,
                ));

            span {
                font-family: var(--font-neue);
                font-weight: 500;
                @include RP(("font-size": 24,
                        "line-height": 28,
                    ));
                color: white;
            }
        }

        &:hover {
            .magnifyContainer {
                display: flex;
            }

            cursor: pointer;
        }
    }

    .btnContainer {
        position: absolute;
        bottom: 20px;
        left: 20px;

        .btnStyle {
            background-color: white;
            color: $BLACK;
            gap: 10px;
        }
    }
}

.smallImageWrapper {
    max-width: 256px !important;
    flex-basis: 30%;
}

@media screen and (min-width:500px) and (max-width:768px) {
    .smallImageWrapper {
        flex-basis: 30%;
    }
}

@media screen and (min-width:330px) and (max-width:499px) {
    .smallImageWrapper {
        flex-basis: 45%;
    }
}


@media screen and (max-width:330px) {
    .smallImageWrapper {
        flex-basis: 100%;
    }
}