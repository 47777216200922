@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.topContainer {
  height: 100vh;
  width: 100%;
  overflow: hidden;

  .mainContainer {
    height: 100vh;
    overflow-y: auto;
  }
}

.menuItem,
#subMenuItem,
.subMenuContainer {
  font-size: 14px;
  font-weight: 500;
  color: $GRAY;
}


.subMenuContainer>a>span:first-child {
  justify-content: start !important;
}


.menuItem span {
  justify-content: flex-start;
}

.active,
.subMenuActive {
  color: $SECONDARY;
  border-left: 3px solid $SECONDARY !important;
}

.active path {
  fill: $SECONDARY;
  stroke: $SECONDARY;
  // stroke-width: 1px;
  /* Change this to whatever color you want */
}

.active svg ellipse {
  fill: $SECONDARY !important;
  stroke: $SECONDARY;
}

.active svg circle {
  fill: $SECONDARY !important;
  stroke: $SECONDARY;
}

// .active svg path {
//   stroke: $SECONDARY !important;
// }