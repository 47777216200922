@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.cont {
  @include RP(
    (
      "width": 24,
      "height": 24,
    )
  );
}
