@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.customBtnContainer {
  background-color: $PRIMARY;
  color: white;
  border: 0px;
  font-family: var(--font-neue);
  justify-content: center;
  align-items: center;
  display: flex;
  width: fit-content;
  @include RP(
    (
      "font-size": 16,
      "border-radius": 10,
      "line-height": 26,
      "padding-left": 34,
      "padding-right": 34,
      "padding-top": 14,
      "padding-bottom": 14,
      "max-height": 44,
    )
  );
  font-weight: 400;
  outline: 0px;
  transition: all 0.5s ease-in-out;
  &:hover {
    opacity: 0.85 !important;
  }
  svg {
    @include RP(
      (
        "height": 24,
        "width": 24,
      )
    );
  }
}

@media screen and (max-width: 768px) {
  .customBtnContainer {
    font-size: 13px;
    line-height: 21px;
  }
}
