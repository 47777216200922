.iconWrapper {
    svg {
        width: 100%;
        height: 100%;
    }
}

.container {
    height: 1000px;
    overflow-y: scroll;

}

.container::-webkit-scrollbar {
    display: none !important;
}