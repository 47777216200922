.sliderWrapper img {
    border-radius: 16px;
}

.slick-slide {
    margin: 0 10px !important;
    /* Adjust the margin value as needed */
}

.sliderWrapper [data-role="none"] {
    display: none !important;
}

.sliderWrapper ul {
    bottom: 25px !important;
    display: flex !important;
    gap: 6px !important;
    justify-content: center;
}

.sliderWrapper ul li {
    padding: 0 !important;
    width: 6px;
    height: 6px;
}

.sliderWrapper ul li button {
    padding: 0 !important;
}

.sliderWrapper ul li button::before {
    color: white !important;
    width: 6px !important;
    height: 6px !important;
}
 