@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

//mixins
@mixin h3Tag {
    text-align: left;
    font-family: var(--font-neue);
    font-weight: 500;
    @include RP(("font-size": 24,
            "line-height": 29,
        ));
}

@mixin singleAmnityContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 10px;

    svg {
        @include RP(("height": 24,
                "width": 24,
            ));
    }

    label {
        text-align: left;
        font-family: var(--font-neue);
        font-weight: 400;
        @include RP(("font-size": 16,
                "line-height": 24,
            ));
        color: #777e90;
    }

    span {
        text-align: left;
        font-family: var(--font-neue);
        font-weight: 500;
        @include RP(("font-size": 16,
                "line-height": 24,
            ));
        color: $PRIMARY;
    }
}

//styling

.topSection {
    .title {
        letter-spacing: 0.01em;
        text-align: left;
        font-family: var(--font-neue);
        font-weight: 500;
        @include RP(("font-size": 42,
                "line-height": 56,
            ));
    }

    .avt {
        @include RP(("height": 32,
                "width": 32,
            ));
        border-radius: 100%;
        object-fit: cover;
    }

    .userName {
        display: flex;
        gap: 0.5rem;
        font-family: var(--font-neue);
        font-weight: 400;
        @include RP(("font-size": 16,
                "line-height": 20,
            ));
        color: $GRAY;

        span {
            font-weight: 500;
            color: $PRIMARY;
        }
    }

    .phoneIcon {
        @include RP(("height": 24,
                "width": 24,
            ));
    }

    .phoneLabel {
        font-family: var(--font-neue);
        font-weight: 400;
        @include RP(("font-size": 16,
                "line-height": 20,
            ));
        color: $GRAY;
    }

    .actionContainer {
        @include RP(("height": 48,
                "width": 48,
            ));
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $BORDER;

        svg {
            @include RP(("height": 24,
                    "width": 24,
                ));
        }

        cursor: pointer;
    }
}

.descriptionContainer {
    h3 {
        @include h3Tag();
    }

    p {
        text-align: left;
        font-family: var(--font-neue);
        font-weight: 400;
        @include RP(("font-size": 16,
                "line-height": 28,
            ));
        color: $GRAY;
    }
}

.aminityContainer {
    h3 {
        @include h3Tag();
    }

    .singleAminity {
        @include singleAmnityContainer();
    }
}

.berthContainer {
    h3 {
        @include h3Tag();
    }

    .slip {
        @include singleAmnityContainer();
    }
}

.mapContainer {
    h3 {
        @include h3Tag();
    }

    .mapContainerElement {
        overflow: hidden;
        width: 100%;
        @include RP(("height": 300,
                "border-radius": 20,
            ));
    }
}

.servicesContainer {
    h3 {
        @include h3Tag();
    }
}

.guiderProfileContainer {
    h3 {
        @include h3Tag();
    }
}