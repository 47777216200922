@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.topContainer {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #141416;
    top: 0;
    left: 0;
    z-index: 2147483647;

    .contentContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh;
        @include RP(("padding-top": 48,
                "padding-bottom": 48,
                "gap": 20,
            ));

        .crossContainer {
            @include RP(("min-height": 40,
                    "min-width": 40,
                    "max-height": 40,
                    "max-width": 40,
                ));
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid rgba($color: #fff, $alpha: 0.1);
            border-radius: 100%;
            width: 100%;
            height: 100%;

            svg {
                @include RP(("height": 24,
                        "width": 24,
                    ));
            }

            .heartIcon {
                path {
                    fill: $GRAY;
                }
            }

            .shareIcon {
                path {
                    stroke: $GRAY;
                }
            }

            .arrowIcon {
                path {
                    fill: white;
                }
            }

            &:hover {
                cursor: pointer;
            }
        }

        .count {
            text-align: center;
            font-family: var(--font-neue);
            font-weight: 700;
            @include RP(("font-size": 14,
                    "line-height": 16,
                ));
            color: #777e90;
        }

        .imgContainer {
            height: 85vh;
            width: 100%;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }
}