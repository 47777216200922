@import "shared/utils/colors.scss";


.hideScrollBar::-webkit-scrollbar {
    height: 6px !important;
}

.hideScrollBar::-webkit-scrollbar-thumb {
    background-color: $SECONDARY !important;
    border-radius: 4px !important;
}

.hideScrollBar::-webkit-scrollbar-track {
    background-color: #FFFFFF !important;
    border-radius: 4px !important;
}