@import "shared/utils/colors.scss";

.iconWrapper svg path {
    fill: $SECONDARY
}

#panel {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}

#panel::-webkit-scrollbar {
    width: 0 !important;
}