@import "shared/utils/colors.scss";

.breadCrumbActive,
.breadCrumbDefault {
    font-family: 'Neue Montreal';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    // margin-left: 10px;
    color: black;
    font-family: inherit;
    line-height: 21.6px;
}

// .breadCrumbDefault {
//     color: $GRAY;
//     // margin-right: 10px;
// }

.svgWrappper svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width:1024px) {

    .breadCrumbActive,
    .breadCrumbDefault {
        font-size: 14px;
    }
}

@media screen and (max-width:500px) {

    .breadCrumbActive,
    .breadCrumbDefault {
        font-size: 11px;
    }
}