$PRIMARY: #466053;
$SECONDARY: #dc6d43;
$GRAY: #868e96;
$BLACK: #0f1106;
$BG: #f2f6fb;
$DARK_BLUE: #1a355c;
$LIGHT_GRAY: #f9f9f9;
$LIGHT_GRAY2: #adb5bd;
$BORDER: #ced4da;
$GRAY900: #160042;
$BORDER2: #e4e6f1;
$GRAY500: #f5f5f6;
$BLACK800: #373737;
$SUCCESS_BG: #ecfdf3;
$SUCCESS: #027a48;
$PENDING_BG: #fffaeb;
$PENDING: #b54708;
