@import "shared/utils/responsive.module.scss";

.containerStyle {
    @include RP(("height": 400,
            "width": 400,
        ));
}

.infoWindowHideClose .gm-ui-hover-effect {
    display: none !important;
}