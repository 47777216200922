.star::before {
    // content: "\2606";
    content: url('../../../assets/icons/star.svg');
    cursor: pointer;
    font-size: 24px;
}

.star.rated::before {
    content: url('../../../assets/icons/filledStar.svg');
    color: tomato;
    font-size: 24px;
    cursor: pointer;
}