@import "shared/utils/colors.scss";

.quillWrapper>div:first-child {
    border: 1px solid $BORDER;
}



.quillWrapper>div:last-child {
    border: 1px solid $BORDER;
    font-family: var(--font-neue);
    font-weight: 400;
}