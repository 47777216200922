@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.header {
  background-color: white;
  align-items: center;
  height: 72px;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
  padding: 15px 22px;

  position: sticky;
  top: 0;
  z-index: 40;
}

@media screen and (max-width:768px) {
  .header {
    height: auto;
  }
}