.phoneInput input {
    height: 100% !important;
    border-radius: 8px;
}

.phoneInput input:focus {
    border: none;
    outline: none;
}

.phoneInput input:-webkit-autofill,
.phoneInput input:-webkit-autofill:hover,
.phoneInput input:-webkit-autofill:focus,
.phoneInput input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.phoneInput .PhoneInputCountryIconImg {
    color: red !important;
}


.phoneInputContainer>div>div>div:nth-child(2) {
    width: 24px !important;
    height: 24px !important;
    overflow: hidden;
    border-radius: 100%;
    border: none !important;
    box-shadow: none;
}

.phoneInputContainer>div>div>div:nth-child(2)>img {
    object-fit: cover !important;
}