@import "../../shared/utils/responsive.module.scss";
@import "../../shared/utils/colors.scss";

.assetImg {
  height: 100vh;
  object-fit: cover;
  width: 100%;
}

.leftContainer {
  height: 100vh;
  overflow-y: auto;

  .logoContainer {
    @include RP(("padding-top": 20,
        "padding-bottom": 20,
      ));

    svg {
      @include RP(("height": 67,
          "width": 195,
        ));
    }
  }

  .formContainer {
    margin-top: auto;
    margin-bottom: auto;
    @include RP(("border-radius": 24,
        "padding": 32,
        "gap": 30,
        "max-width": 514,
      ));
    background-color: $GRAY500;
    width: 100%;
    display: flex;
    flex-direction: column;

    .title {
      font-family: var(--font-neue);
      font-weight: 500;
      @include RP(("font-size": 26,
          "line-height": 31,
        ));
      color: $BLACK;
      text-align: center;
    }

    .subTitle {
      font-family: var(--font-neue);
      font-weight: 400;
      @include RP(("font-size": 16,
          "line-height": 28,
        ));
      color: $GRAY;
      text-align: center;
    }

    .forgotLabel {
      font-family: var(--font-neue);
      font-weight: 400;
      @include RP(("font-size": 15,
          "line-height": 16.8,
        ));
      color: $SECONDARY;
      text-align: right;

      &:hover {
        cursor: pointer;
      }
    }

    .bottomText {
      font-family: var(--font-neue);
      font-weight: 400;
      @include RP(("font-size": 15,
          "line-height": 16.8,
        ));
      color: $GRAY;
      text-align: center;

      a {
        color: $SECONDARY;
      }
    }
  }
}

.dotsContainer {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  z-index: 1;

  .dot {
    height: 8px;
    width: 8px;
    background-color: white;
    opacity: 0.3;
    border-radius: 100%;
  }

  .active {
    opacity: 1 !important;
    width: 32px;
    border-radius: 100px;
  }
}