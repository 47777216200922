@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "neue-monteral-regular";
  src: url("/src/assets/fonts/NeueMontreal-Regular.otf") format("otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "neue-monteral-regular";
  src: url("/src/assets/fonts/NeueMontreal-Medium.otf") format("otf");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

td {
  min-width: 230px;
}
