@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.iconWrapper svg {
    width: 14px !important;
    height: 14px !important;
    color: $SECONDARY;
}

.crossIconWrapper svg path {
    fill: $SECONDARY !important;
}


.searchLocations::-webkit-scrollbar {
    width: 6px !important;
}


.searchLocations::-webkit-scrollbar-thumb {
    background-color: $SECONDARY !important;
    border-radius: 4px !important;
}

// .searchLocations::-webkit-scrollbar-track {
//     background-color: #FFFFFF !important;
//     border-radius: 4px !important;
// }